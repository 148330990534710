import { graphql, useStaticQuery } from 'gatsby'

const queryNews = (props = {limit: null}) => {
  const query = useStaticQuery(
    graphql`
      query NewsQuery {
        query: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "news-post"}}}) {
          edges {
            node {
              html
              fields {
                slug
              }
              id
              frontmatter {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageCaption
                date(formatString: "MMMM DD, Y")
              }
            }
          }
        }
      }
    `
  )
  const {
    query: { edges: entriesData },
  } = query

  const entries = entriesData.map(entry => ({
    id: entry.node.id,
    date: entry.node.frontmatter.date,
    title: entry.node.frontmatter.title,
    image: entry.node.frontmatter.image,
    imageCaption: entry.node.frontmatter.imageCaption,
    description: entry.node.frontmatter.description,
    slug: entry.node.fields.slug,
    body: entry.node.html
  })).splice(0, props.limit)

  return entries
}

export default queryNews
