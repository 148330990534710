import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageLink from '../components/global/PageLink'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'

import queryNews from '../staticQueries/queryNews'
import PageHeading from '../components/global/PageHeading'

export const NewsTemplate = ({ title, helmet, className }) => {
  const entries = queryNews({limit: 3})

  return (
    <div className={className}>
      {helmet || ''}
      <PageHeading title={title} />
      <Container tw="pb-12">
        <Col>
          {entries.map(entry => {
            return (
              <div className="news-item" key={entry.id}>
                <PageLink tw="w-full sm:flex py-10 block border-0 border-b border-solid border-gray-400" to={entry.slug}>
                  {entry.image &&
                    <img 
                      tw="w-full sm:w-64 mb-0 mr-6"
                      src={ 
                        entry.image.childImageSharp
                        ? entry.image.childImageSharp.fluid.src
                        : entry.image
                      }
                     />
                    }
                    <div tw="flex-1">
                      <h3 tw="mb-1 md:mb-3 lg:mb-0"><strong>{entry.title}</strong></h3>
                      <small>{entry.date}</small>
                      <p>{entry.description}</p>
                    </div>
                </PageLink>
              </div>
            )
          })}
        </Col>
      </Container>
    </div>
  )
}

NewsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  helmet: PropTypes.object,
}

const NewsPageComponent = ({ data, className }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <NewsTemplate
        title={post.frontmatter.title}
        className={className}
        helmet={
          <Helmet titleTemplate="%s - Techna NDT">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

NewsPageComponent.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}

const NewsPage = styled(NewsPageComponent)`
  ${tw``}
  .news-item:last-child a {
    border-bottom: 0;
  }
`

export default NewsPage

export const NewsQuery = graphql`
  query NewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
